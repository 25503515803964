import React from 'react';
import PropTypes from 'prop-types';

/**
 * ErrorBoundary Component
 * 
 * A React component that catches JavaScript errors anywhere in their child
 * component tree, logs those errors, and displays a fallback UI.
 * 
 * @component
 * @example
 * <ErrorBoundary
 *   fallback={<CustomErrorComponent />}
 *   onError={(error, errorInfo) => logErrorToService(error, errorInfo)}
 * >
 *   <MyApp />
 * </ErrorBoundary>
 */
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      errorInfo: null 
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true, 
      error 
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo
    });

    // Call error reporting callback if provided
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }

    // Log error to console in development
    if (process.env.NODE_ENV !== 'production') {
      console.error('ErrorBoundary caught an error:', error, errorInfo);
    }
  }

  handleReset = () => {
    this.setState({ 
      hasError: false, 
      error: null, 
      errorInfo: null 
    });
  }

  render() {
    if (this.state.hasError) {
      // If a custom fallback is provided, use it
      if (this.props.fallback) {
        return React.cloneElement(this.props.fallback, {
          error: this.state.error,
          errorInfo: this.state.errorInfo,
          onReset: this.handleReset
        });
      }

      // Default error UI
      return (
        <div className="p-4 border border-red-500 rounded-lg bg-red-50">
          <h1 className="text-xl font-bold text-red-700 mb-2">
            Something went wrong
          </h1>
          <div className="text-red-600 mb-4">
            {this.props.showError && (
              <details className="whitespace-pre-wrap">
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </details>
            )}
          </div>
          {this.props.allowReset && (
            <button
              onClick={this.handleReset}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
            >
              Try Again
            </button>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  /** Child components to be rendered */
  children: PropTypes.node.isRequired,
  /** Custom fallback component to render when an error occurs */
  fallback: PropTypes.element,
  /** Callback function called when an error is caught */
  onError: PropTypes.func,
  /** Whether to show detailed error information */
  showError: PropTypes.bool,
  /** Whether to show the reset button */
  allowReset: PropTypes.bool
};

ErrorBoundary.defaultProps = {
  showError: process.env.NODE_ENV !== 'production',
  allowReset: true
};

// Also export the default for backward compatibility
export default ErrorBoundary;