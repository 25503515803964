import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import { AuthProvider } from './contexts/authcontext.js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './tailwind-output.css';

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);