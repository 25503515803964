import React from 'react';
import HamburgerMenu from '../menu/hamburger.js';

const LayoutWrapper = ({ children }) => {
  return (
    <div className="flex min-h-screen">
      <div className="w-64 flex-shrink-0">
        <HamburgerMenu />
      </div>
      <main className="flex-grow p-4">
        {children}
      </main>
    </div>
  );
};

export default LayoutWrapper;