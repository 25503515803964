import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authcontext.js';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      setIsOpen(false);
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuStyle = {
    position: 'fixed',
    top: '10px',
    left: '10px',
    zIndex: 1000,
  };

  const buttonStyle = {
    fontSize: '24px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '10px',
    cursor: 'pointer',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  };

  const dropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: '0',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.15)',
    display: isOpen ? 'block' : 'none',
    minWidth: '180px',
    overflow: 'hidden',
  };

  const linkStyle = {
    display: 'block',
    padding: '10px 0',
    color: '#333',
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'inherit',
    transition: 'background-color 0.3s, color 0.3s',
    background: 'none',
    border: 'none',
    textAlign: 'left',
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
  };

  const menuItemStyle = {
    padding: '0 10px',
    borderRadius: '4px',
  };

  const MenuItem = ({ to, onClick, children }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    const style = {
      ...menuItemStyle,
      backgroundColor: isHovered ? '#f0f0f0' : 'transparent',
    };
  
    const linkStyleWithHover = {
      ...linkStyle,
      color: isHovered ? '#000' : '#333',
    };
  
    const handleClick = () => {
      if (onClick) onClick();
      setIsOpen(false);
    };
  
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
  
    const commonProps = {
      style: linkStyleWithHover,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onClick: handleClick,
    };
  
    return (
      <div style={style}>
        {to ? (
          <Link to={to} {...commonProps}>
            {children}
          </Link>
        ) : (
          <button {...commonProps}>
            {children}
          </button>
        )}
      </div>
    );
  };
  
  return (
    <div style={menuStyle} ref={menuRef}>
      <button onClick={toggleMenu} style={buttonStyle}>☰</button>
      {isOpen && (
        <div style={dropdownStyle}>
          <MenuItem to="/home">Home</MenuItem>
          <MenuItem to="/notes">Notes</MenuItem>
          <MenuItem to="/privacy">Privacy Policy</MenuItem>
          {user && (
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          )}
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;