// src/App.js
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/authcontext.js';
import LayoutWrapper from './components/ui/layoutwrapper.js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ErrorBoundary from './components/errorhandle/errorboundary.js';
import { ToastProvider } from './components/ui/toast_provider.js'; // Add this import

// Lazy load components
const SignUpPage = lazy(() => import('./pages/auth/signupage.js'));
const LoginPage = lazy(() => import('./pages/auth/loginpage.js'));
const OTPPage = lazy(() => import('./pages/auth/otpage.js'));
const PersonalInfoPage = lazy(() => import('./components/personalinfo/personalinfo.js'));
const Home = lazy(() => import('./components/recording/home.js'));
const NotesPage = lazy(() => import('./components/storescript/notespages.js'));
const PrivacyPolicyPage = lazy(() => import('./pages/policy/privacy.js'));
const ApiKeyManagement = lazy(() => import('./components/api/apikeymanagement.js'));
const ApiDocumentation = lazy(() => import('./components/api/ApiDocumentation.js')); // Import the new component
//const LandingPage = lazy(() => import('./components/landing/landingpage.js')); // Add this line

const LoadingFallback = () => <div>Loading...</div>;

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <LoadingFallback />;
  }
  
  return user ? children : <Navigate to="/login" replace />;
};

const AppContent = () => {
  const { loading } = useAuth();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!loading) {
      setIsReady(true);
    }
  }, [loading]);

  if (!isReady) {
    return <LoadingFallback />;
  }

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/otp" element={<OTPPage />} />
        <Route path="/personalinfo" element={
          <PrivateRoute>
            <LayoutWrapper>
              <PersonalInfoPage />
            </LayoutWrapper>
          </PrivateRoute>
        } />
        <Route path="/home" element={
          <PrivateRoute>
            <LayoutWrapper>
              <Home />
            </LayoutWrapper>
          </PrivateRoute>
        } />
        <Route path="/notes" element={
          <PrivateRoute>
            <LayoutWrapper>
              <NotesPage />
            </LayoutWrapper>
          </PrivateRoute>
        } />
        <Route path="/privacy" element={
          <PrivateRoute>
            <LayoutWrapper>
              <PrivacyPolicyPage />
            </LayoutWrapper>
          </PrivateRoute>
        } />
        <Route path="/api" element={
          <PrivateRoute>
            <LayoutWrapper>
              <ApiKeyManagement />
            </LayoutWrapper>
          </PrivateRoute>
        } />
        <Route path="/api-docs" element={
          <PrivateRoute>
            <LayoutWrapper>
              <ApiDocumentation />
            </LayoutWrapper>
          </PrivateRoute>
        } />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};

const App = () => {
  return (
    <ErrorBoundary>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <AuthProvider>
          <ToastProvider> {/* Add ToastProvider here */}
            <Router>
              <AppContent />
            </Router>
          </ToastProvider>
        </AuthProvider>
      </GoogleReCaptchaProvider>
    </ErrorBoundary>
  );
};

export default App;