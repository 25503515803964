// authcontext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { auth, db, analytics } from '../firebase/firebase.js';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // State variables
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [persistLogin, setPersistLogin] = useState(true);

  // Refs and event arrays
  const recaptchaVerifierRef = useRef(null);
  const inactivityTimeoutRef = useRef(null);
  const activityEvents = [
    'mousedown',
    'mousemove',
    'keypress',
    'scroll',
    'touchstart',
  ];

  // Initialize authentication persistence
  const initializeAuth = useCallback(async () => {
    try {
      await setPersistence(
        auth,
        persistLogin ? browserLocalPersistence : browserSessionPersistence
      );
      logEvent(analytics, 'auth_persistence_set', { persistLogin });
      console.log(
        `Auth persistence set to: ${persistLogin ? 'local' : 'session'}`
      );
    } catch (error) {
      console.error('Error setting auth persistence:', error);
      logEvent(analytics, 'auth_persistence_error', { error: error.message });
    }
  }, [persistLogin]);

  // Sign out function
  const signOut = useCallback(async () => {
    try {
      await auth.signOut();
      setUser(null);
      setConfirmationResult(null);
      logEvent(analytics, 'user_signed_out');

      // Clear inactivity timeout and remove event listeners
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      activityEvents.forEach((eventName) => {
        window.removeEventListener(eventName, resetInactivityTimeout);
      });
    } catch (error) {
      console.error('Error signing out:', error);
      logEvent(analytics, 'sign_out_error', { error: error.message });
      throw new Error('Failed to sign out. Please try again.');
    }
  }, []);

  // Function to reset inactivity timeout
  const resetInactivityTimeout = useCallback(() => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }

    // Set new timeout for 5 hours
    inactivityTimeoutRef.current = setTimeout(() => {
      signOut();
    }, 5 * 60 * 60 * 1000); // 5 hours in milliseconds
  }, [signOut]);

  // Effect to initialize auth and listen for token changes
  useEffect(() => {
    const initAuth = async () => {
      await initializeAuth();

      const unsubscribe = auth.onIdTokenChanged(async (user) => {
        if (user) {
          try {
            setUser(user);

            // Check or create user document in Firestore
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (!userDoc.exists()) {
              await setDoc(doc(db, 'users', user.uid), {
                phoneNumber: user.phoneNumber,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
              });
              logEvent(analytics, 'new_user_created');
            }

            logEvent(analytics, 'user_signed_in');
          } catch (error) {
            console.error('Error setting up user:', error);
            setUser(null);
          }
        } else {
          // User is signed out or token is invalid
          setUser(null);
        }
        setLoading(false);
      });

      return unsubscribe;
    };

    let authUnsubscribe;

    initAuth().then((unsubscribe) => {
      authUnsubscribe = unsubscribe;
    });

    return () => {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
      }
      if (authUnsubscribe) {
        authUnsubscribe();
      }
    };
  }, [initializeAuth]);

  // Effect to handle inactivity logout
  useEffect(() => {
    if (user) {
      // Start inactivity timer
      resetInactivityTimeout();

      // Add event listeners to reset the timer on user activity
      activityEvents.forEach((eventName) => {
        window.addEventListener(eventName, resetInactivityTimeout);
      });
    }

    // Cleanup function
    return () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      activityEvents.forEach((eventName) => {
        window.removeEventListener(eventName, resetInactivityTimeout);
      });
    };
  }, [user, resetInactivityTimeout]);

  // Function to set up reCAPTCHA
  const setupRecaptcha = useCallback((containerId = 'recaptcha-container') => {
    return new Promise((resolve, reject) => {
      try {
        // Clear existing verifier if it exists
        if (recaptchaVerifierRef.current) {
          try {
            recaptchaVerifierRef.current.clear();
          } catch (e) {
            console.warn('Failed to clear existing reCAPTCHA:', e);
          }
        }
  
        // Create new verifier
        recaptchaVerifierRef.current = new RecaptchaVerifier(auth, containerId, {
          size: 'invisible',
          callback: (response) => {
            console.log('reCAPTCHA verified');
            logEvent(analytics, 'recaptcha_verified');
            resolve(response);
          },
          'expired-callback': () => {
            console.log('reCAPTCHA expired');
            logEvent(analytics, 'recaptcha_expired');
            // Reinitialize on expiry
            setupRecaptcha(containerId).catch(console.error);
            reject(new Error('Security check expired. Please try again.'));
          },
          'error-callback': (error) => {
            console.error('reCAPTCHA error:', error);
            logEvent(analytics, 'recaptcha_error', { error: error?.message });
            reject(error);
          }
        });
  
        // Render the reCAPTCHA
        recaptchaVerifierRef.current.render()
          .then(() => {
            console.log('reCAPTCHA rendered');
            resolve();
          })
          .catch((error) => {
            console.error('Failed to render reCAPTCHA:', error);
            reject(error);
          });
  
      } catch (error) {
        console.error('Failed to initialize reCAPTCHA:', error);
        reject(error);
      }
    });
  }, []);

  // Function to send OTP
  const sendOTP = async (phoneNumber) => {
    console.log('sendOTP called with phoneNumber:', phoneNumber);
    if (!recaptchaVerifierRef.current) {
      console.error('reCAPTCHA verifier not initialized');
      throw new Error('reCAPTCHA verifier not initialized');
    }

    try {
      console.log('Attempting to sign in with phone number...');
      const result = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptchaVerifierRef.current
      );
      console.log('Sign-in with phone number successful, result:', result);

      setConfirmationResult(result);
      logEvent(analytics, 'otp_sent_successfully');
      return result;
    } catch (error) {
      console.error('Error during sign-in with phone number:', error);
      logEvent(analytics, 'otp_send_error', {
        error: error.code,
        message: error.message,
      });
      throw error;
    }
  };

  // Function to verify OTP
  const verifyOTP = async (otp) => {
    if (!confirmationResult) {
      logEvent(analytics, 'otp_verify_no_confirmation');
      throw new Error('No confirmation result available. Please request OTP first.');
    }
    try {
      const result = await confirmationResult.confirm(otp);

      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', result.user.uid), {
          phoneNumber: result.user.phoneNumber,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        logEvent(analytics, 'new_user_created_after_otp');
      }
      setUser(result.user);
      console.log('OTP verified, user signed in:', result.user);
      logEvent(analytics, 'otp_verified_successfully');
      await setPersistence(auth, browserLocalPersistence);

      return result.user;
    } catch (error) {
      console.error('Error during OTP verification:', error);
      logEvent(analytics, 'otp_verification_error', { error: error.code });
      if (error.code === 'auth/invalid-verification-code') {
        throw new Error('Invalid OTP. Please try again.');
      } else if (error.code === 'auth/code-expired') {
        throw new Error('OTP has expired. Please request a new one.');
      }
      throw error;
    }
  };

  // Function to toggle login persistence
  const togglePersistence = async (persist) => {
    try {
      await setPersistence(
        auth,
        persist ? browserLocalPersistence : browserSessionPersistence
      );
      setPersistLogin(persist);
      logEvent(analytics, 'persistence_toggled', { persistLogin: persist });
      console.log(`Auth persistence toggled to: ${persist ? 'local' : 'session'}`);
    } catch (error) {
      console.error('Error toggling persistence:', error);
      logEvent(analytics, 'persistence_toggle_error', { error: error.message });
      throw new Error('Failed to change login persistence. Please try again.');
    }
  };

  const value = {
    user,
    loading,
    sendOTP,
    verifyOTP,
    setupRecaptcha,
    confirmationResult,
    signOut,
    persistLogin,
    togglePersistence,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthContext };
export default AuthContext;
