// src/components/ui/toast.js
import React from 'react';

const Toast = ({ message, type = 'info', onClose }) => {
  const bgColor = type === 'success' ? 'bg-green-500' : type === 'error' ? 'bg-red-500' : 'bg-blue-500';

  return (
    <div className={`fixed bottom-4 right-4 ${bgColor} text-white px-4 py-2 rounded shadow-lg`}>
      {message}
      <button onClick={onClose} className="ml-2 font-bold">&times;</button>
    </div>
  );
};

export default Toast;